<template>
    <b-container fluid="">
        <b-table
            hover
            :items="items"
            :fields="fields"
            head-variant="dark"
            @row-dblclicked="toForm"
        >
            <template #cell(actions)="data">
                <span><button class="btn btn-sm btn-outline-success" @click="toForm(data.item)"><i class="fas fa-edit"></i></button></span>
            </template>
            <template #cell(current_responsible_user_name)="data">
                <span>{{data.item.current_responsible_user && data.item.current_responsible_user.fullname || ''}}</span>
            </template>
            <template #cell(responsible_from)="data">
                <span>{{ data.item.current_responsible_user && data.item.current_responsible_user.pivot.date_from || ''}}</span>
            </template>
            <template #cell(responsible_to)="data">
                <span>{{ data.item.current_responsible_user && data.item.current_responsible_user.pivot.date_to || ''}}</span>
            </template>

        </b-table>
    </b-container>
</template>

<script>
import { STORAGE_ENDPOINT } from '@utils/endpoints';

export default {
    name: 'Index',
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'id',
                    label: '№',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Наименование',
                    sortable: true,
                },
                {
                    key: 'area.name',
                    label: 'Наименование площадки',
                },
                {
                    key: 'current_responsible_user_name',
                    label: 'Ответственный',
                },
                {
                    key: 'responsible_from',
                    label: 'Ответственный с',
                },
                {
                    key: 'responsible_to',
                    label: 'Ответственный до',
                },
                {
                    key: 'actions',
                    label: 'Действия',
                },
            ],
        };
    },
    mounted() {
        this.fetchStorages();
    },
    methods: {
        fetchStorages() {
            this.$http
                .get(STORAGE_ENDPOINT, {
                    params: {
                        sort: 'id',
                        sortDirection: 'asc',
                        limit: 100,
                        with: ['responsibleUsers', 'area'],
                        appends: ['current_responsible_user'],
                    }
                })
                .then(response => response.data.data.data)
                .then(data => {
                    this.items = data;
                })
                .catch(error => {
                    this.$root.responseError(error, 'Ошибка!')
                });
        },
        toForm(item = null) {
            if (item) {
                this.$router.push({name: 'StorageEdit', params: {id: item.id}});
            }
        }
    }
}
</script>
